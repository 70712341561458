import React, { useEffect } from 'react';

import Router, { withRouter } from 'next/router';

import { useStateValue } from '../state/stateProvider';

const ErrorPage = ({ router }) => {
  const [{ user }] = useStateValue();
  useEffect(() => {
    if (!user) {
      return;
    }
    if (user === 'error') {
      Router.push({
        pathname: '/auth/login',
        query: router.query,
      });
    } else {
      Router.push({
        pathname: '/',
        query: router.query,
      });
    }
  }, [user]);

  return <></>;
};

export default withRouter(ErrorPage);
